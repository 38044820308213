var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"900"},on:{"click:outside":_vm.close},model:{value:(_vm.dialogReminder),callback:function ($$v) {_vm.dialogReminder=$$v},expression:"dialogReminder"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","inline":""}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:" text-subtitle-1 pl-2"},[_vm._v(_vm._s(_vm.$t('form.required')))])]),_c('template',{slot:"title"},[_c('v-col',[_c('h2',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t('trainee.form.formation.reminders.title'))+" ")])])],1),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainee.form.formation.reminders.date'),"rules":"required"}},[_c('DateField',{attrs:{"label":_vm.$t('trainee.form.formation.reminders.date') + '*',"keep-hour":true,"text-field-class":"ml-3"},model:{value:(_vm.reminder.reminderAt),callback:function ($$v) {_vm.$set(_vm.reminder, "reminderAt", $$v)},expression:"reminder.reminderAt"}})],1)],1),_c('v-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainee.form.formation.reminders.time'),"rules":"required"}},[_c('TimeField',{attrs:{"allowed-minutes":true,"label":_vm.$t('trainee.form.formation.reminders.time') + '*'},model:{value:(_vm.reminder.reminderAt),callback:function ($$v) {_vm.$set(_vm.reminder, "reminderAt", $$v)},expression:"reminder.reminderAt"}})],1)],1),_c('v-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainee.form.formation.reminders.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('v-select',{staticClass:"ml-3",attrs:{"error-messages":errors,"items":['Téléphone', 'Email', 'SMS'],"label":_vm.$t('trainee.form.formation.reminders.type') + '*',"outlined":""},model:{value:(_vm.reminder.reminderType),callback:function ($$v) {_vm.$set(_vm.reminder, "reminderType", $$v)},expression:"reminder.reminderType"}})}}],null,true)})],1),_c('v-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainee.form.formation.reminders.contactType')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('v-select',{staticClass:"ml-3",attrs:{"error-messages":errors,"items":[
                    'Stagiaire',
                    'Loueur',
                    'Commercial concession',
                    'Gestionnaire de parc',
                    'Autres' ],"label":_vm.$t('trainee.form.formation.reminders.contactType'),"outlined":""},model:{value:(_vm.reminder.contactType),callback:function ($$v) {_vm.$set(_vm.reminder, "contactType", $$v)},expression:"reminder.contactType"}})}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('trainee.form.formation.reminders.comment'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return _c('v-textarea',{staticClass:"ml-3",attrs:{"error-messages":errors,"outlined":"","solo":"","label":_vm.$t('trainee.form.formation.reminders.comment')},model:{value:(_vm.reminder.commentary),callback:function ($$v) {_vm.$set(_vm.reminder, "commentary", $$v)},expression:"reminder.commentary"}})}}],null,true)})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"md":"3","sm":"4"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary","outlined":"","block":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")])],1),(!_vm.reminderToEdit)?[_c('v-col',{attrs:{"md":"3","sm":"4"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary","outlined":"","block":""},on:{"click":function($event){return _vm.clear()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.clear'))+" ")])],1),_c('v-col',{attrs:{"md":"3","sm":"4"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary","block":"","type":"submit","loading":_vm.isSubmiting}},[_vm._v(" "+_vm._s(_vm.$t('btn.create'))+" ")])],1)]:[_c('v-col',{attrs:{"md":"3","sm":"4"}},[_c('v-btn',{attrs:{"elevation":"2","color":"primary","block":"","loading":_vm.isSubmiting},on:{"click":_vm.editReminder}},[_vm._v(" "+_vm._s(_vm.$t('btn.save'))+" ")])],1)]],2)],1)]}}])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }