























































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { formatISO } from 'date-fns'
import { Reminder } from '@/api/interfaces/reminder'

export default defineComponent({
  name: 'ReminderForm',
  components: {
    DateField: () => import('@/components/base/DateField.vue'),
    TimeField: () => import('@/components/base/TimeField.vue'),
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    needTrainingId: {
      type: String,
      required: true,
    },
    defaultReminderType: {
      type: String,
      required: false,
      default: 'Téléphone',
    },
    defaultContactType: {
      type: String,
      required: false,
      default: 'Stagiaire',
    },
    reminderToEdit: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      dialogReminder: false,
      isSubmiting: false,
      reminder: {},
    }
  },
  created () {
    this.initReminder()
  },
  watch: {
    show (value) {
      if (value) {
        this.dialogReminder = value
      }
    },
    reminderToEdit: {
      handler () {
        if (this.reminderToEdit === null) {
          this.initReminder()
        } else {
          (this.reminder as Reminder) = { ...this.reminderToEdit as Reminder }
        }
      },
    },
  },
  methods: {
    ...mapActions('reminderForm', {
      save: 'save',
    }),
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    async onSubmit () {
      this.isSubmiting = true
      try {
        this.$emit('created', this.reminder)
        this.clear()
        this.close()
        this.initReminder()
      } finally {
        this.isSubmiting = false
      }
    },
    clear () {
      this.getValidationObserver().reset()
    },
    close () {
      this.dialogReminder = false
      this.$emit('close')
    },
    initReminder () {
      this.reminder = {
        reminderAt: formatISO(new Date()),
        reminderType: this.defaultReminderType,
        commentary: '',
        contactType: this.defaultContactType,
      }
    },
    editReminder () {
      this.isSubmiting = true
      try {
        if ((this.reminder as Reminder)['@id']) {
          this.save(this.reminder)
        }
        this.$emit('replaceReminder', this.reminder, this.reminderToEdit)
        this.clear()
        this.close()
      } finally {
        this.isSubmiting = false
      }
    },
  },
})
